<template>
  <modal
    transition="nice-modal-fade"
    width="680"
    :adaptive="true"
    :reset="true"
    height="auto"
    :name="modalName"
    @before-open="beforeOpen"
    ref="modal"
  >
    <div class="modal--wrapper">
      <div class="back--wrapper" v-if="$mq === 'xs'">
        <div class="back--button" @click="close">
          <img src="@/assets/img/icons/back-2.svg" />
        </div>
        <div v-if="type === 'digital-agreement'">
          {{ $t('booking.digitalAgreementPreview') }}
        </div>
        <div v-else-if="type === 'offer-letter'">
          {{ $t('booking.offerLetterPreview') }}
        </div>
      </div>
      <section class="modal--container accept--survey-container" v-else>
        <div class="title--text">
          <span v-if="type === 'digital-agreement'">
            {{ $t('booking.digitalAgreementPreview') }}
          </span>
          <span v-else-if="type === 'offer-letter'">
            {{ $t('booking.offerLetterPreview') }}
          </span>
        </div>
        <hr />
        <div v-html="digitalSignPreview" class="preview--wrapper"></div>
        <hr class="separator" />
        <div class="d-flex justify-content-between" :class="{ 'flex-wrap': $mq === 'xs' }">
          <div class="btn btn-primary--outline main--btn" @click="close">
            {{ $t('general.btn.close') }}
          </div>
        </div>
      </section>
      <button type="button" class="close" @click="close" v-if="$mq !== 'xs'">
        <img src="@/assets/img/icons/close-1.svg" />
      </button>
    </div>
  </modal>
</template>

<script>
import ModalMixin from '../mixins/index';
import HelperMixin from '@/mixins/helpers';

export default {
  name: 'preview-digital-agreement-modal',
  mixins: [ModalMixin, HelperMixin],
  components: {},
  data() {
    return {
      modalName: 'preview-digital-agreement-modal',
      modalData: null,
      digitalSignPreview: null,
      type: null,
    };
  },
  computed: {},
  methods: {
    async beforeOpen(event) {
      this._closed();
      this.digitalSignPreview = event.params.digitalSignPreview;
      this.type = event.params.type;
    },
    close() {
      this.$modal.hide(this.modalName);
    },
  },
};
</script>

<style scoped></style>
