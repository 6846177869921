export default {
  data() {
    return {
      _applicationUuid: null,
    };
  },
  methods: {
    _closed() {
      this._applicationUuid = null;
      this.modalData = null;
    },
    async _fetchApplicationData() {
      let fetchUrl;
      const upperCaseActionType = this.actionType.toUpperCase();
      console.log('upperCaseActionType', upperCaseActionType);
      if (upperCaseActionType === 'NEGO') {
        fetchUrl = '/api/v1/getApplicationActionData/nego';
      } else if (upperCaseActionType === 'TOUR_PROG') {
        fetchUrl = '/api/v2/get_application_action_data/tour_schedule_prog';
      } else if (upperCaseActionType === 'BOOKING_REQUEST') {
        fetchUrl = '/api/v2/get_application_action_data/booking_request';
      } else if (upperCaseActionType === 'RENT_TRANSACTION') {
        fetchUrl = '/api/v2/get_application_action_data/rent_transaction';
      } else if (upperCaseActionType === 'SELL_TRANSACTION') {
        fetchUrl = '/api/v2/get_application_action_data/sell_transaction';
      } else if (upperCaseActionType === 'FINTECH_INSTALLMENT') {
        fetchUrl = '/api/v2/get_application_action_data/fintech_installment';
      }
      try {
        const { data } = await this._makeGetRequest(fetchUrl);
        this.modalData = data.data;
      } catch (e) {
        console.log('ERROR MAKING REQUEST APPLICATION ACTION: ', e);
      }
    },
    _close() {
      this.$modal.hide(this.modalName);
    },
    async _makeGetRequest(url) {
      return this.$http.get(url, {
        params: {
          applicationUuid: this._applicationUuid,
        },
      });
    },
  },
};
